import { useCallback, useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import useFetcher, { endpoints } from 'src/utils/axios';
import { IOrganization } from 'src/types/organization';
// routes

// hooks
import { useDashboardContext } from 'src/context/hooks';
import { IUser } from 'src/types/user';
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { useAuthContext } from 'src/auth/hooks';
import Scrollbar from '../../../components/scrollbar';
import { ConfirmDialog } from '../../../components/custom-dialog';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
// types
import { IOrgCredentialTableFilterValue, ITableFilters } from '../../../types/orgCredential';
import TableRowMetadata from './table-row-user-organisation';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'displayName', label: 'Organisation Name' },
  { id: 'org_id', label: 'Organisation ID' },
  { id: 'isDefault', label: 'Default Organisation' },
];

const defaultFilters: ITableFilters = {
  name: '',
  status: 'all',
  startDate: null,
  endDate: null,
};
interface ListViewProps {
  allOrgs: IOrganization[] | [];
}
// ----------------------------------------------------------------------

export default function ListView({ allOrgs }: ListViewProps) {
  const { currentUser } = useAuthContext();
  const [user, setUser] = useState<IUser | null>(null);
  const { fetchData, updateData } = useFetcher();
  const setDefaultValue = (orgId: string) => {
    if (user) {
      const newUser = { ...user, org_id: orgId };
      updateData(endpoints.users.put, newUser);
      setUser(newUser);
    }
  };
  useEffect(() => {
    fetchData(endpoints.users.get).then((response) =>
      setUser(response.filter((_user: IUser) => _user.uid === currentUser.uid)[0])
    );
    console.log(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const table = useTable({ defaultOrderBy: 'orderNumber' });

  const confirm = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const dataFiltered = applyFilter({
    inputData: allOrgs,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });

  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 40 : 72;

  const canReset =
    !!filters.name || filters.status !== 'all' || (!!filters.startDate && !!filters.endDate);

  const notFound = (!dataFiltered?.length && canReset) || !dataFiltered?.length;

  const handleFilters = useCallback(
    (name: string, value: IOrgCredentialTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );
  return (
    <>
      <Card
        sx={{
          m: 1,
          p: 6,
        }}
      >
        <Tabs
          value={filters.status}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        />
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={allOrgs.length}
                onSort={table.onSort}
              />

              <TableBody>
                {dataInPage.map((row) => (
                  <TableRowMetadata
                    key={row.org_id}
                    row={row}
                    setDefaultValue={setDefaultValue}
                    user={user}
                  />
                ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, allOrgs.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered?.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: {
  inputData: IOrganization[] | [];
  comparator: (a: any, b: any) => number;
  filters: ITableFilters;
  dateError: boolean;
}) {
  if (Array.isArray(inputData)) {
    const { name } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name) {
      inputData = inputData.filter(
        (item) => item.displayName.toLowerCase().indexOf(name.toLowerCase()) !== -1
      );
    }
  }

  return inputData;
}
