export const GLOSSARY = {
    VIEW_GLOSSARY_LIST: 'viewGlossaryList',
    VIEW_GLOSSARY_ITEM: 'viewGlossary'
}

export const COMPANY_INFO = {
    VIEW_COMPANY_INFO: 'viewCompanyInfo'
}

export const TRANSLATION_ENGINE = {
    VIEW_TRANSLATION_ENGINE: 'viewTranslationEngine'
}

export const TOKEN = {
    VIEW_TOKEN: "viewToken"
}

export const CREDENTIALS = {
    VIEW_CREDENTIALS: "getCredentials"
}

export const APPROVALS = {
    VIEW_APPROVALS: "viewApprovals"
}

export const LOGS = {
    VIEW_LOGS: "viewLogs"
}

export const USER = {
    VIEW_USER: "viewGetUsers"
}

export const METADATATRANSLATOR = {
    VIEW_METADATATRANSLATOR: "viewMetaDataTranslator"
}

export const METADATATRANSLATE_JOB = {
    VIEW_METADATATRANSLATOR_JOB: "viewMetaDataTranslateJob"
}