import { useState, useEffect } from 'react';
import {
    getFirestore,
    collection,
    doc,
    getDoc,
    setDoc,
    where,
    query,
    getDocs,
} from 'firebase/firestore';
import { useAuthContext } from 'src/auth/hooks';

const getResults = async (q: any) => {
    try {
        const querySnapshot = await getDocs(q);
        const results: any = [];
        querySnapshot.forEach((doc_: any) => {
            results.push({ id: doc_.id, ...doc_.data() });
        });
        return results;
    } catch (error) {
        console.error('Error getting documents: ', error);
        throw error;
    }
};

const usePermission = (permission: string) => {
    const { currentUser, db } = useAuthContext();
    const [allowedPermission, setAllowedPermission] = useState(false);
    const [isPermissionLoading, setPermissionLoading] = useState(true);

    const getUserRole = async (userID: string) => {
        try {
            const q = query(collection(db, 'org_to_user'), where('user_id', '==', userID));
            const userData = await getResults(q);
            return userData.length > 0 ? userData[0].role : null;
        } catch (error) {
            console.error('Error getting documents: ', error);
            throw error;
        }
    };

    const getAssignedRoles = async (permission_: string) => {
        try {
            const q = query(
                collection(db, 'permission'),
                where('permission_api_name', '==', permission_)
            );
            const userPermission = await getResults(q);

            if (userPermission.length > 0) {
                return userPermission[0].assigned_roles;
            }
            return null;
        } catch (error) {
            console.error('Error getting documents: ', error);
            throw error;
        }
    };

    useEffect(() => {
        const checkPermission = async () => {
            if (currentUser) {
                const userPermission = await getAssignedRoles(permission);
                const userRole = await getUserRole(currentUser.uid);
                if (userPermission && userRole) {
                    setAllowedPermission(userPermission.includes(userRole));
                    setPermissionLoading(false);
                }
                else {
                    setPermissionLoading(false);
                }
            }
        };
        checkPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, permission, db]);

    return { allowedPermission, isPermissionLoading };
};

export default usePermission;
