import { m } from 'framer-motion';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { _notifications } from 'src/_mock';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
// import { useDashboardContext, useRealTimeContext } from 'src/context/hooks';
import { varHover } from 'src/components/animate';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { sortBy } from 'lodash';
//
import { ILog } from 'src/types/logs';
import { useAuthContext } from 'src/auth/hooks';
import useFetcher, { endpoints } from 'src/utils/axios';
import { IUser } from 'src/types/user';
import { useDashboardContext } from 'src/context/hooks';
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------
const TABS = [
  {
    value: 'all',
    label: 'All',
    count: 0,
  },
  {
    value: 'unread',
    label: 'Unread',
    count: 0,
  },
  {
    value: 'archived',
    label: 'Archived',
    count: 0,
  },
];
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const [currentTab, setCurrentTab] = useState('all');
  const { db } = useAuthContext();
  const { currentOrg } = useDashboardContext();
  const { fetchData } = useFetcher();
  const [user, setUser] = useState<IUser[]>();
  useEffect(() => {
    if (currentOrg) {
      fetchData([endpoints.users.get, { params: { org_id: currentOrg.org_id } }]).then(
        (response: IUser[]) => setUser(response)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg]);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  function arraysEqual(arr1: any, arr2: any) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }
  const [notifications, setNotifications] = useState<ILog[]>();

  useEffect(() => {
    if (currentOrg) {
      const q = query(collection(db, 'notification'), where('org_id', '==', currentOrg.org_id));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const notes: any = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          data.createdDate = new Date(data.createdDate);
          notes.push(data);
        });
        if (!arraysEqual(notes, notifications)) {
          notes.forEach((note: any) => {});

          setNotifications(sortBy(notes, (item) => item.createdDate.getTime()).reverse());
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, currentOrg]);

  const totalUnRead = notifications?.filter((item: any) => item.isUnRead === true).length;

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications?.map((notification: any) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab: any) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications?.map((notification: any) => (
          <NotificationItem key={notification.id} notification={notification} user={user} />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          <IconButton onClick={handleMarkAllAsRead}>
            <Iconify icon="solar:settings-bold-duotone" />
          </IconButton>
        </Stack>

        <Divider />

        {renderList}

        <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            View All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
