import { Outlet } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import { RealTimeProvider } from 'src/context/realtimeUpdates/provider';
import DocsLayout from 'src/layouts/docs/layout';

const queryParameters = new URLSearchParams(window.location.search);
const inviteKey = queryParameters.get('inviteKey');

const MTJobs = lazy(() => import('src/pages/dashboard/MTjobs'));
const GlossaryList = lazy(() => import('src/pages/dashboard/glossaryList'));

const TestPage1 = lazy(() => import('src/pages/docs/page1'));

export const docsRoutes = [
    {
        path: 'docs',
        element: (
            <DocsLayout>
                <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                </Suspense>
            </DocsLayout>
        ),
        children: [
            {
                path: 'page1',
                element: (<TestPage1 />),
            },
            {
                path: 'page2',
                element: (<TestPage1 />),
            },
        ],
    },
];
