// mockdata
export const _translatable = [
  {
    _id: '64ee1fb881090daddf721cd2',
    org_id: '',
    object: 'Opportunity',
    metadata_type_label: 'Button and Link Label',
    metadata_type_api_name: 'WebLink',
    metadata_label_name: 'Delivery Status',
    metadata_api_name: 'DeliveryStatus',
    aspect: '',
    status: 'Pending',
    translations: {
      langauge: 'en_US',
      value: 'Delivery Status',
    },
  },
  {
    _id: '64ee1fb881090daddf721cd3',
    org_id: '',
    object: 'Account',
    metadata_type_label: 'Button and Link Label',
    metadata_type_api_name: 'WebLink',
    metadata_label_name: 'Billing',
    metadata_api_name: 'Billing',
    aspect: '',
    status: 'Pending',
    translations: {
      langauge: 'en_US',
      value: 'Billing',
    },
  },
];

// ----------------------------------------------------------------------

export const TRANSLATION_STATUS_OPTIONS = [
  { value: 'translated', label: 'Translated' },
  { value: 'translation_missing', label: 'Translation Missing' },
  { value: 'deployment_missing', label: 'Deployment Missing' },
  { value: 'translation_in_progress', label: 'Translation In Progress' },
  { value: 'deployment_in_progress', label: 'Deployment In Progress' },
  { value: 'error', label: 'Error' },
];

export interface Translatable {
  _id: string;
  org_id: string;
  object: string;
  api_name: string;
  metadata_type_label: string;
  metadata_type_api_name: string;
  master_label: string;
  metadata_api_name: string;
  aspect: string;
  status: string;
  translations: Translation[];
}

export interface Translation {
  language: string;
  status: string;
  value: string;
  value_translated: string;
}

export const _mockTranslation: Array<Translatable> = JSON.parse(JSON.stringify(_translatable));
