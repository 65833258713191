const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  DOCS: '/docs'
};

// ----------------------------------------------------------------------

export const paths = {
  /* to be changed &checked if really needed */
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: '/docs',

  /* to be changed */

  auth: {
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
  }, // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    metadataTranslator: `${ROOTS.DASHBOARD}/metadataTranslator`,
    jobs: `${ROOTS.DASHBOARD}/jobs`,
    metadataTranslateJob: `${ROOTS.DASHBOARD}/MTJobs`,
    metadataTranslateJobDetails: `${ROOTS.DASHBOARD}/MTJobDetails`,
    companyInformation: `${ROOTS.DASHBOARD}/companyInformation`,
    glossary: `${ROOTS.DASHBOARD}/glossary`,
    credentials: `${ROOTS.DASHBOARD}/credentials`,
    approval: `${ROOTS.DASHBOARD}/approval`,
    log: `${ROOTS.DASHBOARD}/Logs`,
    translationEngine: `${ROOTS.DASHBOARD}/translationEngine`,
    security: `${ROOTS.DASHBOARD}/security`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      list: `${ROOTS.DASHBOARD}/user`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
  },
  docsPath: {
    root: ROOTS.DOCS,
    page1: `${ROOTS.DOCS}/page1`,
    page2: `${ROOTS.DOCS}/page2`
  }
};
