export const _orgCredentials = [
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'test',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'test',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'test',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'antoniosorg',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'matejorg',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'test',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'test',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'antonio',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'antonio',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'antonio1234',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Disconnected',
  },
  {
    org_crediantial_name: 'test',
    org_crediantial_id: 'antonio1234',
    instance_url: 'https://creative-narwhal-mahw0d-dev-ed.trailblaze.my.salesforce.com',
    status: 'Connected',
  },
];

// ----------------------------------------

export const ORG_CREDENTIAL_STATUS_OPTIONS = [
  { value: 'Disconnected', label: 'Disconnected' },
  { value: 'Connected', label: 'Connected' },
];

export interface OrgCredential {
  org_crediantial_id: string;
  org_crediantial_name: string;
  instance_url: string;
  status: string;
}

export const _mockOrgCredentials: Array<OrgCredential> = JSON.parse(
  JSON.stringify(_orgCredentials)
);
