import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import usePermission from 'src/permission/has-permission';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={name} />;

const ICONS = {
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('mdi:account'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  salesforce: icon('mdi:salesforce'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  library: icon('mdi:library'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  job: icon('iconamoon:synchronize-duotone'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  account: icon('solar:home-bold-duotone'),
  credential: icon('mdi:cloud-key'),
  approval: icon('wpf:approval'),
  security: icon('wpf:key-security'),
  dict: icon('streamline:dictionary-language-book-solid'),
  translation: icon('dashicons:translation'),
};

// ----------------------------------------------------------------------

export function useDocsNavData() {
  // eslint-disable-next-line arrow-body-style
  const managementItems = useMemo(() => {
    return [
      {
        title: 'Item 1',
        path: paths.docsPath.page1,
        icon: ICONS.file,
        children: [
          {
            title: 'Sub item 1',
            path: paths.docsPath.page1,
            icon: ICONS.mail,
          },
          {
            title: 'Sub item 2',
            path: paths.docsPath.page2,
            icon: ICONS.calendar,
          },
        ],
      },
    ];
  }, []);

  const data = useMemo(
    () => [
      {
        subheader: 'Test Heading',
        items: managementItems,
      },
    ],
    [managementItems]
  );

  return data;
}
