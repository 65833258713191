import {useMemo} from 'react';
// routes
import {paths} from 'src/routes/paths';
// components
import usePermission from 'src/permission/has-permission';
import {
    GLOSSARY,
    TOKEN,
    CREDENTIALS,
    APPROVALS,
    METADATATRANSLATOR,
    METADATATRANSLATE_JOB,
    TRANSLATION_ENGINE,
    USER,
    COMPANY_INFO,
    LOGS,
} from 'src/permission/all_permissions';
import Iconify from '../../components/iconify';
// ----------------------------------------------------------------------

const icon = (name: string) => (// <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
    // OR
    <Iconify icon={name}/>
    // https://icon-sets.iconify.design/solar/
    // https://www.streamlinehq.com/icons
);

const ICONS = {
    blog: icon('ic_blog'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('mdi:account'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    tour: icon('ic_tour'),
    salesforce: icon('mdi:salesforce'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    library: icon('mdi:library'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    product: icon('ic_product'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    job: icon('iconamoon:synchronize-duotone'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
    account: icon('solar:home-bold-duotone'),
    credential: icon('mdi:cloud-key'),
    approval: icon('wpf:approval'),
    security: icon('wpf:key-security'),
    dict: icon('streamline:dictionary-language-book-solid'),
    translation: icon('dashicons:translation'),
};

const GetPermissions = () => ({
    allowedCompanyInfo: usePermission(COMPANY_INFO.VIEW_COMPANY_INFO),
    allowedTranslationEngine: usePermission(TRANSLATION_ENGINE.VIEW_TRANSLATION_ENGINE),
    allowedGlossaryList: usePermission(GLOSSARY.VIEW_GLOSSARY_LIST),
    allowedToken: usePermission(TOKEN.VIEW_TOKEN),
    allowedCredentials: usePermission(CREDENTIALS.VIEW_CREDENTIALS),
    allowedLogs: usePermission(LOGS.VIEW_LOGS),
    allowedApprovals: usePermission(APPROVALS.VIEW_APPROVALS),
    allowedUser: usePermission(USER.VIEW_USER),
    allowedMetadataTranslator: usePermission(METADATATRANSLATOR.VIEW_METADATATRANSLATOR),
    allowedMetadataTranslateJobs: usePermission(METADATATRANSLATE_JOB.VIEW_METADATATRANSLATOR_JOB),
});

// ----------------------------------------------------------------------
export function useNavData() {
    const {
        allowedCompanyInfo,
        allowedTranslationEngine,
        allowedGlossaryList,
        allowedToken,
        allowedCredentials,
        allowedLogs,
        allowedApprovals,
        allowedUser,
        allowedMetadataTranslator,
        allowedMetadataTranslateJobs,
    } = GetPermissions();

    const managementItems = useMemo(() => {
        const items = [];


        if (allowedApprovals) {
            items.push({
                title: 'Approval', path: paths.dashboard.approval, icon: ICONS.approval,
            });
        }


        if (allowedCompanyInfo) {
            items.push({
                title: 'Company Information', path: paths.dashboard.companyInformation, icon: ICONS.account,
            });
        }
        if (allowedGlossaryList) {
            items.push({
                title: 'Glossary', path: paths.dashboard.glossary, icon: ICONS.dict,
            });
        }
        if (allowedLogs) {
            items.push({
                title: 'Log', path: paths.dashboard.log, icon: ICONS.job,
            });
        }
        if (allowedCredentials) {
            items.push({
                title: 'Org Credential', path: paths.dashboard.credentials, icon: ICONS.credential,
            });
        }
        if (allowedToken) {
            items.push({
                title: 'Token', path: paths.dashboard.security, icon: ICONS.security,
            });
        }

        if (allowedTranslationEngine) {
            items.push({
                title: 'Translation Engine', path: paths.dashboard.translationEngine, icon: ICONS.translation,
            });
        }
        if (allowedUser) {
            items.push({
                title: 'User', path: paths.dashboard.user.list, icon: ICONS.user,
            });
        }
        return items;
    }, [allowedCompanyInfo, allowedUser, allowedApprovals, allowedCredentials, allowedToken, allowedGlossaryList, allowedLogs, allowedTranslationEngine,]);

    const overviewItems = useMemo(() => {
        const items = [];

        if (allowedMetadataTranslator) {
            items.push({
                title: 'Metadata Translator', path: paths.dashboard.root, icon: ICONS.salesforce,
            });
        }

        if (allowedMetadataTranslateJobs) {
            items.push({
                title: 'Metadata Translate Job', path: paths.dashboard.metadataTranslateJob, icon: ICONS.job,
            });
        }

        return items;
    }, [allowedMetadataTranslator, allowedMetadataTranslateJobs]);

    const data = useMemo(() => [// OVERVIEW
        // ----------------------------------------------------------------------
        {
            subheader: 'Simple Translate', items: overviewItems,
        },

        // MANAGEMENT
        // ----------------------------------------------------------------------
        {
            subheader: 'setup', items: managementItems,
        },], [overviewItems, managementItems]);

    return data;
}
